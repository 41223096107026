import axios from 'axios';

export interface VerifyRequest {
  code: string;
}

export type VerifyResponse = {
  success: boolean;
  message?: string;
  redirect?: string;
};

const verifyLogin = async (userId: number, data: VerifyRequest): Promise<VerifyResponse> => {
  const reply = await axios.post(`/api/verify-login/${userId}`, data);
  return reply.data;
};

export default verifyLogin;
